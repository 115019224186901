<template>
  <div class="container">
    <div class="mt-4 mb-4">
      <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
        <Preloader/>
      </div>
      <div v-else>
        <div v-if="isSelectTopic">
          <p class="text-center fs-3">Тема дипломной работы</p>
          <div class="card mt-4">
            <div class="card-body">
              <p class="fs-5"><span class="badge btn-primary">ҚАЗ</span>
                {{graduateWork.topic.theme_name_kz}}
              </p>
              <p class="fs-5"><span class="badge btn-primary">РУС</span>
                {{graduateWork.topic.theme_name_ru}}
              </p>
              <p class="fs-5"><span class="badge btn-primary">ENG</span>
                {{graduateWork.topic.theme_name_en}}
              </p>
              <p class="fs-5 mt-5">Руководитель: {{graduateWork.topic.lastname}} {{graduateWork.topic.firstname}}
                {{graduateWork.topic?.middlename}}
              </p>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-center fs-3 mb-4">Выберите тему дипломной работы</p>

          <DataTable :value="graduateWork.topics" stripedRows showGridlines>

            <Column header="Тема" style="width: 50%">
              <template #body="{data}">
                <p class="fs-6"><span class="badge btn-primary">ҚАЗ</span>
                  {{data.theme_name_kz}}
                </p>
                <p class="fs-6"><span class="badge btn-primary">РУС</span>
                  {{data.theme_name_ru}}
                </p>
                <p class="fs-6"><span class="badge btn-primary">ENG</span>
                  {{data.theme_name_en}}
                </p>
              </template>
            </Column>


            <Column field="author" header="Автор темы">
              <template #body="{data}">
                {{data.lastname}} {{data.firstname}}
              </template>
            </Column>
            <Column header="Руководитель">
              <template #body>
                <select class="form-select" v-model="supervisor_id">
                  <option v-for="(pps, ppsIndex) in graduateWork.pps" :key="ppsIndex" :value="pps.pps_id">
                    {{pps.lastname}} {{pps.firstname}}
                  </option>
                </select>
              </template>
            </Column>
            <Column header="Выбрать">
              <template #body="{data}">
                <button class="btn btn-primary" @click="selectTopic(data.theme_id)">Выбрать</button>
              </template>
            </Column>
          </DataTable>
        </div>

      </div>

    </div>


  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "GraduateWorkTopic",
    data() {
      return {
        loading: true,
        isSelectTopic: true,
        supervisor_id: null
      }
    },
    computed: {
      ...mapState('graduateWork', ['graduateWork']),
    },

    methods: {
      ...mapActions('graduateWork', ['GET_GRADUATE_WORK_TOPIC', 'GET_GRADUATE_WORK_TOPICS', 'GET_PPS', 'POST_GRADUATE_WORK_TOPIC']),
      async selectTopic(graduate_work_theme_id) {
        console.log('graduate_work_theme_id', graduate_work_theme_id)
        console.log('supervisor_id', this.supervisor_id)
        const res = await this.POST_GRADUATE_WORK_TOPIC({graduate_work_theme_id, supervisor_id: this.supervisor_id})
        if (res) {
          this.loading = true
          this.isSelectTopic = true
          await this.setData()
        } else {
          this.$error({text: 'Произошла ошибка'})
        }
      },
      async setData() {
        const res = await this.GET_GRADUATE_WORK_TOPIC()
        if (!res) {
          await this.GET_PPS()
          await this.GET_GRADUATE_WORK_TOPICS()
          this.isSelectTopic = false
        }
        this.loading = false
      }
    },
    async mounted() {
      await this.setData()
    }
  }
</script>

<style scoped>

</style>